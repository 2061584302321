<template>
    <b-container fluid>
        <iq-card>
                <template v-slot:body>
                <b-row>
                    <b-col>
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('fertilizerConfig.allocationType.allocationType')"
                    label-for="email"
                    >
                    <v-select name="croptypeID"
                    v-model="search.type_name"
                    label="text"
                    :options= allocationTypeList
                    />
                    </b-form-group>
                    </b-col>
                    <b-col >
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('fertilizerConfig.allocationType.allocationType')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" variant="primary" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table head-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(description)="data">
                                        <span class="capitalize">{{ data.item.description }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                                <i class="ri-ball-pen-fill"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Click to Active" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                                <i class="fas fa-toggle-on"></i>
                                            </a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Click to Deactive" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                                <i class="fa fa-toggle-off"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { allocationTypeList, allocationTypeToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form
    },
    data () {
        return {
            search: {
                type_name: 0
            }
        }
    },
    computed: {
        allocationTypeList: function () {
            const tmpList = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
            return tmpList
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('fertilizerConfig.allocationType.allocationType') + ' ' + this.$t('globalTrans.entry') : this.$t('fertilizerConfig.allocationType.allocationType') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('fertilizerConfig.allocationType.allocationType'), class: 'text-center' },
                { label: this.$t('fertilizerConfig.descriptionEn'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'type_name_bn' },
                { key: 'description_bn' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'type_name' },
                { key: 'description' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
        this.changeStatus(seedFertilizerServiceBaseUrl, allocationTypeToggleStatus, item, 'seeds_fertilizer', 'allocationTypeList')
        },
        loadData () {
            var fertilizerTypeSearch = ''
            if (this.search.type_name) {
                fertilizerTypeSearch = this.search.type_name.value
            }
            const params = Object.assign({}, this.search, { type_name: fertilizerTypeSearch, page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, allocationTypeList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        }
    }
}
</script>
